import { ITag } from './model/applicationModels';

export interface CefContent {
    JsonResponseContent: string;
    JsonResponseContentByPage: string;
    DocumentFileName: string;
    DocumentFileContent: string;
    tags: ITag[];
}

export async function getBlobData(content: string, type: string = 'application/octet-stream'): Promise<Blob> {
    let response = await fetch(`data:${type};base64,${content}`);
    return await response.blob();
}
